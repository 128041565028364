<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="returnGoods_content wrap bgw">
          <div class="state">
            <el-steps :active="state" align-center>
              <el-step title="买家申请退款" description></el-step>
              <el-step title="卖家处理退款" description></el-step>
              <el-step title="商家确认退款" description></el-step>
            </el-steps>
          </div>
          <div class="box">
            <div class="returnTxt">
              <div class="fl txtTitle">
                退款说明
                <span>({{txt.length}}/150字)</span>
              </div>
              <div class="fl txt">
                <textarea v-model="txt" maxlength="150"></textarea>
              </div>
            </div>
            <div class="returnMoney">
              <div class="fl txtTitle">退款金额 </div>
			  <span>￥{{infor.price*1000*infor.num/1000}}</span>
            </div>
            <button @click="checking">确定</button>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "../header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部

import { applyBackMoney } from "@/request/index";
import { getOrderDetails } from "@/request/index"; // 订单详情
import { usergetinfo } from "@/request/user";

export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      id: "",
      state: 1,
      money: "",
      txt: "",
      list: [],
      userList: "",// 个人信息
	  infor:"",
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
	this.infor=this.$route.query.infor;
	console.log(this.$route.query.infor);
    this.id = this.$route.query.id;
    this.init();
    this.start();
  },
  methods: {
    // 初始化
    start() {
      getOrderDetails({
        token: this.$tokens,
        id: this.id
      }).then(res => {
        this.list = res.data.datas;
      });
    },
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
		console.log( this.userList);
      });
    },
    send() {
      let Athis = this;
      applyBackMoney({
        token: this.$tokens,
        did:this.infor.did,
        refund_money: this.infor.price*1000*this.infor.num/1000,
        apply_reason: this.txt
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success"
          });
          setTimeout(function() {
            Athis.$emit("GoAllOrder");
          }, 600);
		  this.$router.push({name:"我的订单详情",query:{id:this.id}});
          // this.$router.push('/user/OrderDetails?id=' + this.id);
        }
      });
    },
    checking() {
      if (this.txt == "") {
        this.$message.error("请填写退款说明");
      } else{
		   this.send();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.returnGoods_content {
  min-height: 700px;
  padding-bottom: 70px;

  .state {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .box {
    padding-left: 100px;
    padding-right: 100px;
    textarea {
      resize: none;
    }
  }

  .returnTxt {
    overflow: hidden;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(26, 26, 26, 1);

    .txtTitle {
      width: 110px;
      text-align: center;
    }

    .txt {
    }

    span {
      display: block;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(128, 128, 128, 1);
    }

    textarea {
      padding-top: 20px;
      text-indent: 20px;
      width: 460px;
      height: 159px;
      border: 1px solid rgba(128, 128, 128, 1);
      font-size: 18px;
    }
  }

  .returnMoney {
    overflow: hidden;
    margin-top: 22px;
	span{
		font-size: 20px;
		color: red;
	}
    .txtTitle {
      width: 110px;
      text-align: center;
    }

    .txt {
      input {
        margin-right: 8px;
      }
    }

    input {
      text-align: center;
      font-size: 18px;
      width: 116px;
      height: 53px;
      border: 1px solid rgba(128, 128, 128, 1) !important;
    }
  }

  button {
    font-size: 18px;
    margin-top: 30px;
    width: 130px;
    height: 46px;
  }
}
</style>